import { render, staticRenderFns } from "./addDocument.vue?vue&type=template&id=7678ff92&scoped=true&"
import script from "./addDocument.vue?vue&type=script&lang=js&"
export * from "./addDocument.vue?vue&type=script&lang=js&"
import style0 from "./addDocument.vue?vue&type=style&index=0&id=7678ff92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7678ff92",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDialog,VIcon,VSelect,VSpacer})
