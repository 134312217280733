<template>
  <v-dialog
    persistent
    v-model="profileDialogAddDocument"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <div class="display-flex align-items-center justify-content-center">
        <img
          width="90px"
          src="@/assets/icons/page.png"
          alt=""
        >
      </div>
      <p class="text-title mon-bold">{{ texts.userprofile.attachments.buttonAddDocument }}</p>
      <div class="mt-8">
        <v-select
          v-model="documentType"
          :items="itemsDocumentsType"
          item-text="sName"
          item-value="sVendorFileTypeId"
          :label="texts.userprofile.attachments.selectTypeDocument"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular"
          :placeholder="texts.userprofile.attachments.selectTypeDocument"
        >
        </v-select>
        <div class="mt-4">
          <v-btn
            v-if="!imageSelectedName"
            @click="$refs.inputUpload.click()"
            class="button-add-document mon-medium"
            elevation="0"
          >
            {{ texts.userprofile.attachments.textFile }}
            <input
              id="inputUpload"
              ref="inputUpload"
              style="display: none;"
              type="file"
              size="60"
              accept=".doc, .docx, .pdf"
              @change="uploadFilesWarehouse"
              :multiple="false"
            >
          </v-btn>
          <div
            v-else
            class="button-add-document display-flex align-items-center justify-content-center"
          >
            <p class="text-file-name mon-medium">{{ this.imageSelectedName }}</p>
            <v-btn
              @click="deleteFile"
              icon
            >
              <v-icon
                color="#D8686A"
                size="12px"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-if="isResponsive"
        class="mt-9"
      >
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.userprofile.attachments.buttonAdd }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.userprofile.attachments.buttonCancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="mt-9 display-flex align-items-center"
      >
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular"
          style="width: 45%;"
        >
          {{ texts.userprofile.attachments.buttonCancel }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 45%;"
        >
          {{ texts.userprofile.attachments.buttonAdd }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProfileAddDocumentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    //VARIABLES
    return {
      documentType: "",
      itemsDocumentsType: [],
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      bShowCurrentPassword: false,
      bShowNewPassword: false,
      bShowConfirmNewPassword: false,
      screenWidth: 0,
      bLoading: false,
      imageSelected: [],
      imageSelectedName: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getNameFiles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    deleteFile: function () {
      this.imageSelected = [];
      this.imageSelectedName = "";
      // document.getElementById("inputUpload").value = '';
    },
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageSelected = files[0];
          this.imageSelectedName = files[0].name;
          // this.sendFileWarehouse(files[0], files[0].name);
        });
      }
    },
    getNameFiles: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(`${URI}/api/v1/${this.selectLanguage}/files/vendors`, config)
        .then((response) => {
          this.itemsDocumentsType = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setProfileDialogAddDocument", false);
      this.imageSelectedName = "";
      this.imageSelected = [];
      this.documentType = "";
    },
    saveChanges: function () {
      this.bLoading = true;
      const payload = { sName: this.imageSelectedName };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/files/${this.documentType}`,
        payload,
        config
      )
        .then((response) => {
          this.sendImage(response.data.results.sVendorFileTypeId);
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    sendImage(id) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      var form = new FormData();
      form.append("file", this.imageSelected);

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/files/${id}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;

          this.mixError(err.response.data.message);
        });
    },
  },
  computed: {
    // return create contact dialog state
    profileDialogAddDocument: function () {
      return this.$store.state.profileDialogAddDocument;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return this.imageSelectedName !== "" && this.documentType !== "";
    },
  },
  watch: {
    profileDialogAddDocument: function () {
      if (this.profileDialogAddDocument) {
        // this.texts = FILE.userprofile[this.selectLanguage];
        this.getNameFiles();
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        // this.texts = FILE.userprofile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.text-file-name {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 0px;
  font-size: 11px;
}

.button-add-document {
  background-color: #ffffff !important;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0px;
  color: #707070;
  width: 100%;
  height: 100px !important;
  text-transform: initial;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}
</style>